import axios from "axios";
import camelCaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

const csrfToken = (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content;

const internalApiClient = axios.create({
  headers: {
    "Content-Type": "application/json",
    "X-CSRF-Token": csrfToken,
  },
  responseType: "json",
});

internalApiClient.interceptors.request.use((request) => {
  if (request.data) request.data = snakecaseKeys(request.data, { deep: true });
  if (request.params) request.params = snakecaseKeys(request.params, { deep: true });
  return request;
});

internalApiClient.interceptors.response.use((response) => {
  response.data = camelCaseKeys(response.data, { deep: true });
  return response;
});

export { internalApiClient };
